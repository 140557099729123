.description{
    /*width:250px;*/
    font-size:20px;
    /*margin:0.5em;*/
    /*padding: 0.5em;*/
    height:50px;
    line-height: 1.25;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.description p {
    margin: 0;
}
